<template>
  <v-row v-if="detail">
    <v-col cols="6" class="border--right">
      <v-row>
        <v-col cols="6">
          {{ $t('refund.orderNumber') }}
        </v-col>
        <v-col cols="6">
          {{ detail.order ? detail.order.order_number : '' }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          {{ $t('refund.productName') }}
        </v-col>
        <v-col cols="6">
          {{ detail.order ? detail.order.product_name : '' }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          {{ $t('refund.agentName') }}
        </v-col>
        <v-col cols="6">
          {{ detail.order ? detail.order.agent_name : '' }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          {{ $t('refund.agentEmail') }}
        </v-col>
        <v-col cols="6">
          {{ detail.order ? detail.order.agent_email : '' }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          {{ $t('refund.agentPhone') }}
        </v-col>
        <v-col cols="6">
          {{ detail.order ? detail.order.agent_phone : '' }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          {{ $t('refund.salesName') }}
        </v-col>
        <v-col cols="6">
          {{ detail.order ? detail.order.sales_name : '' }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          {{ $t('refund.paidAmount') }}
        </v-col>
        <v-col cols="6">
          {{ detail.order ? currencyFormat(detail.order.paid_amount) : '' }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          {{ $t('refund.paymentVia') }}
        </v-col>
        <v-col cols="6">
          {{ detail.order ? detail.order.payment_via : '' }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          {{ $t('refund.createdAt') }}
        </v-col>
        <v-col cols="6">
          {{
            detail.order && detail.order.created_at != null
              ? format(new Date(detail.order.created_at), 'dd MMMM yyyy HH:mm')
              : ''
          }}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="6">
      <v-row>
        <v-col cols="6">
          {{ $t('refund.bank') }}
        </v-col>
        <v-col cols="6">
          {{ detail.bank }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          {{ $t('refund.accountNumber') }}
        </v-col>
        <v-col cols="6">
          {{ detail.account_number }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          {{ $t('refund.accountHolder') }}
        </v-col>
        <v-col cols="6">
          {{ detail.account_holder }}
        </v-col>
      </v-row>
      <hr />
      <v-row>
        <v-col cols="6">
          {{ $t('refund.refundNumber') }}
        </v-col>
        <v-col cols="6">
          {{ detail.refund_number }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          {{ $t('refund.createdBy') }}
        </v-col>
        <v-col cols="6">
          {{ detail.created_by }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          {{ $t('refund.status') }}
        </v-col>
        <v-col cols="6">
          {{ detail.status }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          {{ $t('refund.refundAmount') }}
        </v-col>
        <v-col cols="6">
          {{ detail.refund_amount ? $n(detail.refund_amount, 'currency', 'id-ID') : '' }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          {{ $t('refund.refundReason') }}
        </v-col>
        <v-col cols="6">
          {{ detail.refund_reason }}
        </v-col>
      </v-row>
      <v-row v-if="detail.cancel_reason">
        <v-col cols="6">
          {{ $t('refund.cancelReason') }}
        </v-col>
        <v-col cols="6">
          {{ detail.cancel_reason }}
        </v-col>
      </v-row>
      <v-row v-if="detail.proof_file_url">
        <v-col cols="6">
          {{ $t('refund.proofFile') }}
        </v-col>
        <v-col cols="6">
          <a :href="detail.proof_file_url" target="_blank">
            <img :src="detail.proof_file_url" alt="Proof File" class="detail--image" />
          </a>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: ['detail'],
  data: () => ({
    format,
  }),
};
</script>
